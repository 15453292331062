import { stableHash } from "utils/hashUtils";

export type PatchOperation = 
  | { op: 'add'; path: string; value: unknown }
  | { op: 'remove'; path: string }
  | { op: 'replace'; path: string; value: unknown };

type JsonValue = 
  | string
  | number
  | boolean
  | null
  | JsonValue[]
  | { [key: string]: JsonValue };

const isObject = (value: unknown): value is Record<string, unknown> => 
    typeof value === 'object' && value !== null && !Array.isArray(value);

const escapeJsonPointer = (str: string): string => 
    str.replace(/~/g, '~0').replace(/\//g, '~1');

const deepEqual = (value1: unknown, value2: unknown): boolean => {
    if (value1 === value2) return true;
    return stableHash(value1) === stableHash(value2);
};

const isPathDirty = (
    jsonPointerPath: string, 
    dirtyFields?: string[]
): boolean => {
    if (!dirtyFields) return true;
    
    // '' is always dirty if dirtyFields has entries
    if (jsonPointerPath === '' && dirtyFields.some(f => f?.length > 0)) {
        return true;
    }

    const currentPath = jsonPointerPath
        .slice(1)
        .replace(/\/(\d+)/g, '[$1]')
        .replace(/\//g, '.');

    // Get array path by stripping array indices
    const currentArrayPath = currentPath.replace(/\[\d+\]/g, '');

    return dirtyFields.some(dirtyField => {
        // Strip array indices from dirty field for array-level comparison
        const dirtyArrayPath = dirtyField.replace(/\[\d+\]/g, '');

        // Exact match
        if (currentPath === dirtyField) return true;

        // If dirty field contains array indices, check if it matches the array path
        if (dirtyField.includes('[')) {
            return currentArrayPath === dirtyArrayPath || 
                   dirtyArrayPath.startsWith(currentArrayPath + '.') ||
                   currentArrayPath.startsWith(dirtyArrayPath + '.');
        }

        // Check if any dirty field is a child of current path
        if (dirtyField.startsWith(currentPath)) {
            const nextChar = dirtyField[currentPath.length];
            return !nextChar || nextChar === '.' || nextChar === '[';
        }

        return false;
    });
};

const generateArrayPatches = (
    initialArray: unknown[],
    newArray: unknown[],
    path: string,
    dirtyFields?: string[]
): PatchOperation[] => {
    const patches: PatchOperation[] = [];
    const maxLength = Math.max(initialArray.length, newArray.length);

    let removeCount = 0

    for (let i = 0; i < maxLength; i++) {
        const currentPath = `${path}/${i}`;


        if (!isPathDirty(currentPath, dirtyFields)) continue;

        if (i >= initialArray.length) {
            const addPath = `${path}/-`;
            patches.push({ op: 'add', path: addPath, value: newArray[i] });
        } else if (i >= newArray.length) {
            // Remove by index
            removeCount++
            patches.push({ op: 'remove', path: `${path}/[index=${initialArray.length - removeCount }]` });
        } else if (!deepEqual(initialArray[i], newArray[i])) {
            if (isObject(initialArray[i]) && isObject(newArray[i])) {
                patches.push(
                    ...generateJSONPatch(initialArray[i], newArray[i], currentPath, undefined)
                );
            } else {
                patches.push({ op: 'replace', path: currentPath, value: newArray[i] });
            }
        }
    }

    return patches;
};

const generateObjectPatches = (
    initialObj: Record<string, unknown>,
    newObj: Record<string, unknown>,
    path: string,
    dirtyFields?: string[]
): PatchOperation[] => {
    const patches: PatchOperation[] = [];
    const allKeys = Array.from(new Set([...Object.keys(initialObj), ...Object.keys(newObj)]));

    for (const key of allKeys) {
        const escapedKey = escapeJsonPointer(key);
        const currentPath = path ? `${path}/${escapedKey}` : `/${escapedKey}`;
        
        if (!isPathDirty(currentPath, dirtyFields)) continue;

        if (!(key in initialObj)) {
            patches.push({ op: 'add', path: currentPath, value: newObj[key] });
        } else if (!(key in newObj)) {
            patches.push({ op: 'remove', path: currentPath });
        } else {
            patches.push(
                ...generateJSONPatch(initialObj[key], newObj[key], currentPath, undefined)
            );
        }
    }

    return patches;
};

export const generateJSONPatch = (
    initialData: unknown,
    newData: unknown,
    path: string = '',
    dirtyFields?: string[]
): PatchOperation[] => {
    if (initialData === newData) return [];
    if (!isPathDirty(path, dirtyFields)) return [];

    if (typeof initialData === 'undefined') {
        return [{ op: 'add', path, value: newData }];
    }
    if (typeof newData === 'undefined') {
        return [{ op: 'remove', path }];
    }

    if (typeof initialData !== typeof newData) {
        return [{ op: 'replace', path, value: newData }];
    }

    if (Array.isArray(initialData) && Array.isArray(newData)) {
        return generateArrayPatches(initialData, newData, path, dirtyFields);
    }

    if (isObject(initialData) && isObject(newData)) {
        return generateObjectPatches(initialData, newData, path, dirtyFields);
    }

    if (initialData !== newData) {
        return [{ op: 'replace', path, value: newData }];
    }

    return [];
};

export default generateJSONPatch;