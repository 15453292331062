import { useState } from 'react';
import { createFormRecord } from 'utils/formsApi';
import alerts from 'modules/yoio/state/alerts';
import { isHavingValue } from 'utils/objectUtils';
import { removeArrayItem } from 'utils/arrayUtils';
import { getWindowLocationHrefSafe } from 'utils/windowUtils';
import { useRouter } from 'next/router';
import { FormDef, FormRecordPatchableProps } from './FormTypes';
import { requireValue } from 'modules/picasso-modules/utils/validateUtils';
import { buildAbsoluteUrlWithCurrentHost, buildUrlWithParameterAppended, getPathname, isAbsoluteUrl } from 'utils/urlUtils';
import { notify } from 'modules/yoio/errorsService';
import generateJSONPatch from './jsonpatch';
import { getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { useSWRConfig } from 'swr';

/**
 * submit generic form records
 */

export interface UseFormSubmitProps {
    form: FormDef
    formRecordId?: string
    formRecordPatchable?: FormRecordPatchableProps
    onSuccess?: (responseData: any) => Promise<any>
    onError?: (data?: any)=>void
    initialData?: any
}

export const useFormSubmit = ({form, formRecordId, formRecordPatchable, onSuccess, onError, initialData}:UseFormSubmitProps) => {

    const router = useRouter()

    const [createdAtMs] = useState(Date.now())

    const swrconfig = useSWRConfig()

    const [progress, setProgress] = useState<boolean>()

    const getSecondsSinceCreate = () => {
        return Math.round((Date.now() - createdAtMs) / 1000);
    }

    const submitForm = async (data:object&{metaLocation?: string}, dontContinueToAfterSubmitUrl?:boolean, dirtyFields?: string[]) => {
        if (!form.formId) {
            console.error('unexpected error - no form id');
            return;
        }

        setProgress(true)

        let update = null;

        // Is update
        if (formRecordId) {
            requireValue(formRecordPatchable)

            const jsonpatch = generateJSONPatch(initialData, data, '', dirtyFields);

            for (const op of jsonpatch) {
                if (op.path.startsWith('/')) {
                    op.path = op.path.substring(1, op.path.length)
                }
            }

            update = { jsonpatch }
        }
        const errors = [];

        if (isJustOneDataFieldAndEmpty(data)) {
            errors.push('Field cannot be empty')
        }

        if (errors.length > 0) {
            alerts.error(errors[0]);
            if (onError) {
                onError(data)
            }
            return;
        }

        if (form.isProtectionEnabled && getSecondsSinceCreate() <= 3) {
            //Spam Protection
            alerts.error('Unexpected error. Please try again.');
            if (onError) {
                onError(data)
            }
            return;
        }

        data.metaLocation = getWindowLocationHrefSafe() //its just about the "ref" query param

        const onAfterSuccess = (recordId:string) => {
            const isUpdate = formRecordId !== null && formRecordId !== undefined

            if (!isUpdate && form.afterSubmitUrl && dontContinueToAfterSubmitUrl !== true) {

                let urlEffective = form.afterSubmitUrl+'';

                try {
                    if (form.afterSubmitUrlParameterFormRecordId !== undefined && form.afterSubmitUrlParameterFormRecordId !== null) {
                        const formRecordId = `forms/${requireValue(form.formId)}/records/${requireValue(recordId)}`

                        if (!isAbsoluteUrl(urlEffective)) {
                            urlEffective = buildAbsoluteUrlWithCurrentHost(urlEffective)
                        }

                        urlEffective = buildUrlWithParameterAppended(urlEffective, form.afterSubmitUrlParameterFormRecordId, formRecordId)
                    }
                } catch (error) {
                    notify(error)
                    notify(new Error(`failed to generate urlEffective with formRecordId. proceeding with the original url. formRecordId:${recordId}`))
                    urlEffective = form.afterSubmitUrl
                }

                router.push(urlEffective)
            } else {
                setProgress(false)
            }
        }

        let restHandler: Promise<any>;

        if (update) {
            if (update.jsonpatch.length === 0) {
                // Nothing to do
                restHandler = null
            } else {
                restHandler = getAxiosDefault().patch(formRecordPatchable.url, update.jsonpatch).then((res)=>{
                    // Extraxt pathname as fetchkey in case url is absolute URL
                    const fetchkey = getPathname(formRecordPatchable.url)
                    return swrconfig.mutate(fetchkey).then(()=>res)
                })
            }
        } else {
            restHandler = createFormRecord(form.formId, data)
        }

        if (restHandler == null) {
            // Nothing to do
            setProgress(false)
            return Promise.resolve();
        }

        return restHandler.then((res)=>{
           
            const modifiedRecordId = update ? formRecordId : res.data.formRecordId

            if (!form.afterSubmitUrl) {
                alerts.success('Submitted.');
            }

            if (onSuccess) {
                onSuccess(res.data).then(()=>{
                    onAfterSuccess(modifiedRecordId)
                })
            } else {
                onAfterSuccess(modifiedRecordId)
            }
        }).catch((e) => {
            alerts.error('Unexpected error. Please try again.');
            if (onError) {
                onError(data)
            }
            setProgress(false)
        });


    };

    return { submitForm, progress, forwarding: progress && isHavingValue(form.afterSubmitUrl) }


}

const isJustOneDataFieldAndEmpty = (data) => {
    const realDataKeys = Object.keys(data);
    removeArrayItem(realDataKeys, 'submit')
    removeArrayItem(realDataKeys, 'pageOrigin')

    let isError = false;

    //validation
    if (realDataKeys.length === 1) {
        //just one field - check if it is empty
        realDataKeys.forEach((v)=>{
            const value = data[v];
            if (!isHavingValue(value) || value.length === 0) {
                isError = true;
            }
        })
    }

    return isError;
}